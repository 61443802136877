//--------
// Buttons
//--------
.button {
  padding: 0.625rem 1.5rem;
  height: 2.5rem;
  min-width: 6rem;
  border-radius: $border-radius;

  font-size: $font-size-large;
  font-weight: $font-weight-medium;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  border: 1px solid;
  // outline: none;

  transition: $transition-all;
  -moz-transition: $transition-all;
  -webkit-transition: $transition-all;
  -o-transition: all $transition-all;

  &:hover, &.hover {
    text-decoration: none;
  }

  &.small {
    height: 2rem;
    font-size: $font-size-small;
    line-height: 0.85;
  }

  &:focus, &.focus {
    // box-shadow: 0 0 0 2px rgba($secondary, 0.8);
  }

  // Primary
  &.primary {
    border-color: $primary;
    background-color: $primary;

    &:hover, &.hover {
      background-color: rgba($primary, 0.5);
    }

    &:active, &.active {
      background-color: $primary;
      color: $dark-purple;
    }

    // Small
    &.small {
      border-color: #fff;
      background-color: rgba(0, 0, 0, 0.1);

      &:hover, &.hover {
        background-color: rgba(0, 0, 0, 1);
        border-color: rgba(255, 255, 255, 0.7);
      }

      &:active, &.active {
        background-color: #fff;
        color: $dark-grey;
      }
    }
  }

  // Secondary
  &.secondary {
    border-color: $light-grey;
    background-color: $light-grey;

    &:hover, &.hover {
      border-color: $lighter-grey;
    }

    &:active, &.active {
      background-color: $lighter-grey;
      border-color: $lighter-grey;
      color: $dark-grey;
    }

    // Small
    &.small {
      border-color: rgba(0, 0, 0, 0.2);
      background-color: rgba(0, 0, 0, 0.2);

      &:hover, &.hover {
        background-color: rgba(0, 0, 0, 1);
        border-color: rgba(0, 0, 0, 1);
      }

      &:active, &.active {
        background-color: rgba(0, 0, 0, 1);
        border-color: #fff;
        color: #fff;
      }

      &:disabled, &.disabled {
        background-color: rgba($light-grey, 0.2);
        border-color: rgba($light-grey, 0.2);
      }
    }
  }

  // Disabled
  &:disabled, &.disabled {
    border-color: $lighter-grey;
    color: $lighter-grey;
    background-color: transparent;
    pointer-events: none;
    opacity: 0.5;
  }
}

.button-label {
  font-size: $font-size-small;
  font-weight: $font-weight-medium;
  text-decoration: none;
  color: #fff;

  &:hover, &.hover {
    color: #fff;
    text-decoration: underline;
  }

  &:active, &.active {
    color: $primary;
    text-decoration: none;
  }

  &:disabled, &.disabled {
    color: $lighter-grey;
    pointer-events: none;
    opacity: 0.5;
  }

  &.alt {
    text-decoration: underline;

    &:hover, &.hover {
      text-decoration: none;
    }
  }
}
