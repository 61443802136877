.about {
    @include make-row();
    margin-left: 0;
    margin-right: 0;
    margin-top: 4rem;
    margin-bottom: 6rem;

    h1, h2, p, ul, .get-started {
        @include make-col-ready();
        @include make-col-offset(3);
        @include make-col(6);

        @include media-breakpoint-down(lg) {
            @include make-col-offset(1);
            @include make-col(10);
        }
    }

    h1 {
        margin-bottom: 2.5rem;
        font-size: 4rem;
        font-weight: 300;
    }

    h2 {
        margin-top: 3rem;
        font-weight: 700;
    }

    h3 {
        display: inline;
        font-size: 1rem;
        font-weight: 700;
    }

    ul li {
        margin-bottom: 1rem;
    }

    ul ul {
        @include make-col-offset(0);
        @include make-col(12);
        padding-left: 2rem;
    }

    .get-started {
        margin-top: 2rem;
        
        button {
            background-color: transparent;
            border-color: white;

            &:hover {
                background-color: white;
                color: black;
            }
        }
    }
}