.channel-container {
    margin: 0;
    padding: 0;
    list-style: none;

    .player-container {
        margin: 0;
        padding: 0;
        line-height: 0;
        aspect-ratio: auto 16 / 9;
        display: flex;
        width: auto;
        text-align: right;

        iframe {
            display: flex;
            flex: 1 0 auto;
            margin: 0;
            padding: 0;
            border: 0;
        }

        .channel-offline {
            aspect-ratio: auto 16 / 9;
        }
    }

    .chat-container {
        height: 100%; //Needed for Twitch Chat
        width: 100%;
        margin: 0;
        padding: 0;
        align-self: stretch;
        aspect-ratio: unset;

        iframe {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
        }

        .channel-offline {
            height: 100%;
            background-color: rgba(black, 1);

            .channel-name {
                display: none;
            }
        }
    }

    .channel-offline {
        margin: 0 auto;
        // height: 100%;
        max-width: 100%;
        
        background-color: rgba(black, 0.5);

        .offline-message {
            height: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
                width: 100%;
                font-size: 1rem;

                &.channel-name {
                    padding-bottom: 0.5rem;
                    font-size: 1.25rem;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    color: #fff;
                }
            }
        }
        
    }
}

.chat-only {
    .chat-container {
        .channel-offline {
            .offline-message {
                height: auto;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;

            .channel-name {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
    }
}