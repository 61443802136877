@import 'node_modules/bootstrap/scss/bootstrap';

/* Global */
@import 'fonts';
@import 'variables';
@import 'base';

/* Compontent */
@import 'components/type';
@import 'components/buttons';
@import 'components/forms';

/* Layouts */
@import 'layouts/grid';
@import 'layouts/stream-controls';
@import 'layouts/home';
@import 'layouts/about';
@import 'layouts/channels';
@import 'layouts/focusmode';