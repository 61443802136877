.streams-container {
    @include make-row();
    margin: 0;
    padding: 0;
    height: calc(100dvh - #{$stream-controls-height});
    max-width: 100%;
    position: relative;
    z-index: 2;

    .channel-container {
        @include make-col-ready();
        margin: 0;
        padding: 0;
        line-height: 0;

        &.the-chats {
            .chat-container {
                z-index: 1;
                &.active {
                    z-index: 100;
                }
            }
        }
    }

    &.chats-open {
        overflow-y: hidden;
    }

    .tabs {
        display: none;
    }

    .chat-banner {
        display: none;
    }

    .chat-banner {
        width: 100%;
        // display: block;
        height: $chat-banner-height;
        line-height: 1;

        &:before {
            border-top: 1px;
            border-top-style: solid;
            padding-bottom: 0.25rem;
            content: "";
            width: 100%;
            height: 1px;
            display: block;
        }

        p {
            padding-left: 0.125rem;
        }
    }

    .twitch {
        .chat-banner:before {
            border-top-color: $twitch-color;
        }
    }

    .youtube {
        .chat-banner:before {
            border-top-color: $youtube-color;
        }
    }

    &.chat-only {
        padding: 0.5rem;
            .the-chats {
                flex-direction: row;

                .chat-container {
                    overflow: hidden;

                    .chat-banner {
                        display: block;
                    }

                    iframe {
                        border-radius: $border-radius;
                        height: calc(100% - #{$chat-banner-height});
                    }

                    .channel-offline {
                        overflow: hidden;
                        border-radius: $border-radius;
                        height: calc(100% - #{$chat-banner-height});
                    }
                }
            }
    }

    &.two-channels, &.three-channels {
        &.chat-only {
            .the-chats {
                flex-direction: row;
            }
            .chat-container {
                margin-left:calc(-0.5 * var(--bs-gutter-y));
                margin-right: calc(0 * var(--bs-gutter-x));
                padding-left: calc(var(--bs-gutter-x) * .25);
                padding-right: calc(var(--bs-gutter-x) * .25);
            }
        }
    }

    &.four-channels, &.five-channels, &.six-channels {
        .tabs {
            @include make-row();
            margin: 0;
            height: 4rem;
            width: 100%;
            z-index: 200;
            font-family: $font-family-inconsolata;
            font-weight: $font-weight-medium;

            .tab {
                @include make-col-ready();
                @include make-col(6);
                padding-top: 0.6rem;
                height: 2rem;
                line-height: 1;
                text-align: center;
                cursor: pointer;
                font-size: 0.8125rem;
                text-transform: uppercase;
                color: $light-purple;
                transition: $transition-all;

                &:hover {
                    background-color: rgba(white, 0.05);
                }

                &.active {
                    background-color: rgba($light-purple, 0.2);
                    color: #fff;
                }

                &.twitch {
                    border-color: rgba($twitch-color, 0.5);
                    &:hover {
                        border-color: rgba($twitch-color, 1);
                    }
                }

                &.youtube {
                    border-color: rgba($youtube-color, 0.5);
                    &:hover {
                        border-color: rgba($youtube-color, 1);
                    }
                }

                &:nth-child(odd) {
                    border-left-style: solid;
                    border-left-width: 2px;
                }

                &:nth-child(even) {
                    border-right-style: solid;
                    border-right-width: 2px;
                }
            }
        }

        &.chat-only {
            .the-chats {
                flex-direction: row;
                grid-column-gap: 0.5rem;
                grid-row-gap: 0.5rem;
            }

            .tabs {
                display: none;
            }

            .channel-container {
                &.the-videos {
                    display: none;
                }

                &.the-chats {
                    width: 100%;
                    display: grid;
                    overflow-x: auto;

                    .chat-container {
                        width: 100%;
                        min-width: 24rem;
                        padding-top: 0;
                        position: relative;
                    }
                }
            }
        }
    }

    /***************************************************
    One Channel
    ***************************************************/
    &.one-channel {
        .channel-container {
            &.the-videos {
                @include make-col(9);
                align-self: center;

                .player-container {
                    margin: 0 auto;
                    max-height: calc(100vh - #{$stream-controls-height});
                }
            }
            &.the-chats {
                @include make-col(3);
            }
        }

        &.hide-chat {
            .the-videos {
                @include make-col(12);
            }

            .the-chats {
                display: none;
            }
        }

        &.chat-only {
            .channel-container {
                height: 100%;
                &.the-videos {
                    display: none;
                }
                &.the-chats {
                    @include make-col(12);
                }
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            .channel-container {
                &.the-videos {
                    width: 100%;
                    align-self: unset;
                    flex-grow: 0;
                }

                &.the-chats {
                    width: 100%;
                    flex-grow: 1;
                }
            }
        }
    }

    /***************************************************
    Two Channel
    ***************************************************/
    &.two-channels {
        flex-direction: column;

        .channel-container {
            @include make-row();
            margin: 0;

            &.the-videos {
                .player-container {
                    @include make-col(6);
                    max-height: calc(50dvh - #{$stream-controls-height});

                    iframe {
                        justify-self: flex-end;
                    }
                }
            }

            &.the-chats {
                flex: 1;

                .chat-container {
                    @include make-col(6);
                }
            }
        }

        &.chat-only {
            .channel-container {
                &.the-videos {
                    display: none;
                }
            }
        }

        &.hide-chat {
            .the-videos {
                @include make-col(12);
                flex: 1 auto;
                align-items: center;

                .player-container {
                    max-height: 100%;
                }
            }

            .the-chats {
                display: none;
            }
        }

        @include media-breakpoint-down(lg) {
            &:not(.focus-mode, .ios-iphone-safari) {
                flex-direction: column;
                .the-videos,
                .the-chats {
                    @include make-col(12);
                }

                .the-videos {
                    max-height: 50dvh;
                    flex-direction: column;
                    align-items: center;
                    .player-container {
                        position: relative;
                        z-index: 1;
                        opacity: 1;
                        width: 100%;
                        max-height: 25dvh;
                    }
                }

                .the-chats {
                    height: calc(50dvh - #{$stream-controls-height});
                }
            }
        }
    }

    /***************************************************
    Three Channel
    ***************************************************/
    &.three-channels {
        flex-direction: column;

        .channel-container {
            @include make-row();
            margin: 0;

            &.the-videos {
                .player-container {
                    @include make-col(4);
                    max-height: 100vh;
                }
            }

            &.the-chats {
                flex: 1;

                .chat-container {
                    @include make-col(4);
                }
            }
        }

        &.chat-only {
            .channel-container {
                &.the-videos {
                    display: none;
                }
                &.the-chats {
                    margin: 0;
                }
            }
        }

        &.hide-chat {
            .the-videos {
                @include make-col(12);
                flex: 1 auto;
                align-items: center;
            }

            .the-chats {
                display: none;
            }
        }
    }

    /***************************************************
    Four Channel
    ***************************************************/
    &.four-channels {
        flex-direction: row;

        .channel-container {
            @include make-row();
            margin: 0;

            &.the-videos {
                @include make-col(9);

                .player-container {
                    @include make-col(6);
                    max-height: calc(50vh - #{$stream-controls-height} / 2);

                    &:nth-child(1), &:nth-child(2) {
                        align-self: flex-end;
                    }

                    &:nth-child(3), &:nth-child(4){
                        align-self: flex-start;
                    }
                }
            }
            &.the-chats {
                @include make-col(3);
                margin: 0;
                padding: 0;
                position: relative;

                .chat-container {
                    padding-top: 4rem;
                    position: absolute
                }
            }
        }

        &.chat-only {
            .channel-container {
                &.the-videos {
                    @include make-col(12);
                    aspect-ratio: auto;
                }
                &.the-chats {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: 1fr;

                    .chat-container {
                        width: 100%;
                        min-width: 24rem;

                        &:nth-child(1){
                            grid-area: 1 / 1 / 2 / 2;
                        }
                        &:nth-child(2){
                            grid-area: 1 / 2 / 2 / 3;
                        }
                        &:nth-child(3){
                            grid-area: 1 / 3 / 2 / 4;
                        }
                        &:nth-child(4){
                            grid-area: 1 / 4 / 2 / 5;
                        }
                    }
                }
            }
        }

        &.hide-chat {
            @media screen and (min-width: 1680px) {
                margin: 0 auto;
                aspect-ratio: 16 / 9;
            }

            .the-videos {
                @include make-col(12);
                flex: 1 auto;
                align-items: center;
            }

            .the-chats {
                display: none;
            }
        }
    }

    /***************************************************
    Five Channel
    ***************************************************/
    &.five-channels {
        flex-direction: row;

        .channel-container {
            @include make-row();
            margin: 0;

            &.the-videos {
                @include make-col(9);
                align-self: center;
                justify-content: center;

                .player-container {
                    max-height: calc(50vh - #{$stream-controls-height} / 2);

                    &:nth-child(1){
                        @include make-col(6);
                        align-self: flex-end;
                    }
                    &:nth-child(2){
                        @include make-col(6);
                        align-self: flex-end;
                    }
                    &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                        @include make-col(4);
                    }
                }
            }
            &.the-chats {
                @include make-col(3);
                margin: 0;
                padding: 0;
                position: relative;

                .chat-container {
                    padding-top: 6rem;
                    position: absolute
                }
            }
        }

        &.chat-only {
            .channel-container {
                &.the-chats {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    grid-template-rows: 1fr;
                    overflow-x: auto;

                    .chat-container {
                        width: 100%;
                        min-width: 24rem;

                        &:nth-child(1){
                            grid-area: 1 / 1 / 2 / 2;
                        }
                        &:nth-child(2){
                            grid-area: 1 / 2 / 2 / 3;
                        }
                        &:nth-child(3){
                            grid-area: 1 / 3 / 2 / 4;
                        }
                        &:nth-child(4){
                            grid-area: 1 / 4 / 2 / 5;
                        }
                        &:nth-child(5){
                            grid-area: 1 / 5 / 2 / 6;
                        }
                    }
                }
            }
        }

        &.hide-chat {
            .the-videos {
                @include make-col(12);
            }

            .the-chats {
                display: none;
            }
        }
    }

    /***************************************************
    Six Channel
    ***************************************************/
    &.six-channels {
        flex-direction: row;

        .channel-container {
            @include make-row();
            margin: 0;

            &.the-videos {
                @include make-col(9);
                align-self: center;
                justify-content: center;

                .player-container {
                    max-height: calc(50vh - #{$stream-controls-height} / 2);
                    @include make-col(4);

                    @media screen and (max-width: 1346px) {
                        @include make-col(6);
                    }
                }
            }
            &.the-chats {
                @include make-col(3);
                margin: 0;
                padding: 0;
                position: relative;

                .chat-container {
                    padding-top: 6rem;
                    position: absolute
                }
            }
        }

        &.chat-only {
            .channel-container {
                &.the-chats {
                    grid-template-columns: repeat(6, 1fr);
                    grid-template-rows: 1fr;

                    .chat-container {
                        &:nth-child(1){
                            grid-area: 1 / 1 / 2 / 2;
                        }
                        &:nth-child(2){
                            grid-area: 1 / 2 / 2 / 3;
                        }
                        &:nth-child(3){
                            grid-area: 1 / 3 / 2 / 4;
                        }
                        &:nth-child(4){
                            grid-area: 1 / 4 / 2 / 5;
                        }
                        &:nth-child(5){
                            grid-area: 1 / 5 / 2 / 6;
                        }
                        &:nth-child(6){
                            grid-area: 1 / 6 / 2 / 7;
                        }
                    }
                }
            }
        }

        &.hide-chat {
            .the-videos {
                @include make-col(12);
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);

                .player-container {
                    @media screen and (max-width: 1012px) {
                        @include make-col(6);
                    }

                    @media screen and (min-width: 1013px) {
                        @include make-col(4);
                    }

                    .video-container, .offline-message {
                        height: 100%;
                        aspect-ratio: 16 / 9;
                    }
                }
            }

            .the-chats {
                display: none;
            }
        }
    }

    /***************************************************
    Mobile Layout
    ***************************************************/
    &.two-channels, &.three-channels, &.four-channels, &.five-channels, &.six-channels {
        @include media-breakpoint-down(md) {

            .channel-container {
                flex-direction: column;
                flex-wrap: nowrap;

                &.the-videos {
                    width: 100%;
                    flex-grow: 1;
                    align-self: unset;
                    display: inline-flex;
                    flex-grow: 0;
                    .player-container {
                        @include make-col(12);
                        
                        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                            @include make-col(12);
                            align-self: center;
                        }
                    }
                }
                &.the-chats {
                    width: 100%;
                    flex: 1 1 auto;
                    flex-direction: row;
                    overflow-x: auto;
                    position: absolute;
                    top: 0.5rem;
                    bottom: 0.5rem;
                    left: 0rem;
                    right: 0rem;
                    @include make-col(12);

                    .tabs {
                        display: none;
                    }

                    .chat-container {
                        padding-top: 0;
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                        width: 90%;
                        z-index: 100;
                        position: relative;

                        .chat-banner { 
                            line-height: 1;
                            display: inline-block;
                            bottom: 0;
                        }

                        iframe, .channel-offline {
                            border-radius: 0.25rem;
                            overflow: hidden;
                        }

                        iframe {
                            min-width: calc(100vw - 4rem);
                            height: 86dvh;
                        }
                    }
                }
                
                &:after {
                    position: fixed;
                    z-index: 2;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba(black, 0.6);
                    content: "";
                }
            }

            &.chat-only {
                .the-chats {
                    display: flex;
                    flex-direction: row;
                    overflow-x: auto;

                    .chat-container {
                        @include make-col(11);
                    }
                }
            }
        }
    }

    &.hide-chat {
        .channel-container {
            &:after {
                display: none !important;
            }
        }
    }
}

// Adjust the height for Safari's overlay chrome on iOS (iPhone or iPad)
.ios-iphone-safari .streams-container {
    height: calc(100dvh - #{$stream-controls-height});
    @include media-breakpoint-down(md) {
        // Safari on iOS
        // Approximate height of Safari's overlay chrome: 118px
        height: calc(100dvh - #{$stream-controls-height});

        &.two-channels {
            .the-videos {
                max-height: inherit;
            }
        }

        .channel-container.the-chats {
            .channel-container {
                margin-bottom: 0;
            }
        }
    }
}

.ios-ipad-safari .streams-container {
    height: calc(100dvh - #{$stream-controls-height});
}