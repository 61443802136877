//-------
// Input
//-------
.input {
  padding: 0.5rem 1rem;
  width: 100%;
  height: 3rem;
  min-width: 6rem;
  color: #fff;
  font-size: $font-size-large;
  font-weight: $font-weight-regular;
  line-height: 1.9;
  background-color: $dark-grey;
  border: 2px solid $yellow;
  border-radius: $border-radius;
  display: inline-block;
  // outline: none;

  transition: $transition-all;
  -moz-transition: $transition-all;
  -webkit-transition: $transition-all;
  -o-transition: all $transition-all;

  &:hover, &.hover {
    background-color: $darker-grey;
    border-color: $orange;
  }

  &:focus, &.focus {
    // background-color: $grey;
    // border-color: $orange;
    // box-shadow: 0 0 0 2px rgba($secondary, 0.8);
  }

  &:disabled, &.disabled {
    background-color: transparent;
    opacity: 0.5;
    pointer-events: none;
  }

  &:invalid,
  &.is-invalid {
    border-color: $support-alert;
  }
  &:valid,
  &.is-valid {
    // border-color: $support-success;
  }

  &[contenteditable] {
    overflow: hidden;
    white-space: nowrap;
  }
}


.label {
  margin: 0;
  padding: 0 0 0.5rem;
  font-size: $font-size-large;
}

.input-group {
  margin-bottom: 2rem;
}


//-----------------
// Checkbox & Radio
//-----------------
.form-check {
  margin-bottom: 0.5rem;
  padding-left: 0;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 2.25rem;
  }

  label::before,
  label::after {
    position: absolute;
    content: "";
    display: inline-block;
  }

  label::before {
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid $lighter-grey;
    background-color: rgba(0,0,0,0.3);
    border-radius: 2px;
    left: 0px;
    top: 3px;
    cursor: pointer;
    .radio {
      border-radius: 50%;
    }
  }

  label:hover {
    &::before {
      border-color: $primary;
    }
  }

  label::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 0.48rem;
    top: 0.71rem;
    color: $primary;
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }
  /*Unhide the checkmark on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }
  /*Adding focus styles on the outer-box of the fake checkbox*/
  input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }

  .form-control {
    width: 1.5rem;
    height: 1.5rem;
  }
  .form-check-label {
    font-size: 0.8125rem;
    text-transform: none;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 2rem;
      cursor: pointer;
      line-height: normal;
      display: inline-block;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid $primary;
      border-radius: 100%;
      background: rgba(0,0,0,0.3);
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 1rem;
      height: 1rem;;
      background: $primary;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      border: none;
  }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}


//-----------------------
// Toggle Switch Checkbox
//-----------------------
.toggle {
  position: relative;
  display: inline-block;
  width: 2.875rem;
  height: 1.5rem;
  z-index: 999;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + {
      .slider {
        background-color: #66FF78;
      }
    }

    &:focus + {
      .slider {
        box-shadow: 0 0 1px rgba(0,0,0,0.25);
      }
    }

    &:checked + {
      .slider {
        &:before {
          -webkit-transform: translateX(1.35rem);
          -ms-transform: translateX(1.35rem);
          transform: translateX(1.35rem);
        }
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $darker-grey;
    -webkit-transition: .4s;
    transition: $transition-speed;
    transition-timing-function: $transition-ease-type;
    // border: 1px solid $light-purple;
    border: 1px solid #fff;


    &:before {
      position: absolute;
      content: "";
      height: 1.25rem;
      width: 1.25rem;
      left: calc(0.125rem - 1px);
      bottom: calc(0.125rem - 1px);
      background-color: white;
      box-shadow: 0 0 0.25rem rgba(0,0,0,0.7);
      transition: $transition-speed;
    }

    &.round {
      border-radius: 0.25rem;

      &:before {
        border-radius: 0.15rem;
      }
    }
  }

  &.expand {
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;

    input {
      &:checked + {
        .slider {
          background-color: transparent;
          transform: rotate(180deg);
          transition: $transition-speed;
        }
      }
    }

    .slider {
      background-color: transparent;
      background-image: get-icon( 'openDown', #fff );
      background-repeat: no-repeat, repeat;
      background-position: right 0em top 50%, 0 0;
      background-size: 1.5rem auto, 100%;

      &:before {
        display: none;
      }
    }
  }
}
