//-----------------
// Base Type Styles
//-----------------
h1, h2, h3, h4, h5, h6 {
	color: $headings-color;
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	small {
		color: $headings-small-color;
		font-weight: normal;
		line-height: 1;
	}
}

h1,
h2,
h3 {
	margin-top: $line-height-computed;
	margin-bottom: calc(#{$line-height-computed} / 2);
	small {
		font-size: 65%;
	}
}

h4,
h5,
h6 {
	margin-top: calc(#{$line-height-computed} / 2);
	margin-bottom: calc(#{$line-height-computed} / 2);
	small {
		font-size: 75%;
	}
}

h1 {
	font-size: $font-size-h1;
}
h2 {
	font-size: $font-size-h2;
}
h3 {
	font-size: $font-size-h3;
}
h4 {
	font-size: $font-size-h4;
}
h5 {
	font-size: $font-size-h5;
}
h6 {
	font-size: $font-size-h6;
}

//------------
//Type Classes
//------------
.displayA, .displayB, .displayC, .displayD, .titleLarge, .titleMedium, .titleSmall, .bodyLarge, .bodySmall, .caption {
	font-weight: $font-weight-regular;
	line-height: $line-height-computed;
}

//--------------------
// Display Type Styles
//--------------------
.displayA {
	font-size: $font-size-h1;
	font-weight: $font-weight-light;
}

.displayB {
	font-size: $font-size-h2;
}

.displayC {
	font-size: $font-size-h3;
	font-weight: $font-weight-light;
}

.displayD {
	font-size: $font-size-h4;
	font-weight: $font-weight-medium;
}

//------------------
// Title Type Styles
//------------------
.titleLarge {
	font-size: $font-size-large;
}

.titleMedium {
	font-size: $font-size-small;
}

.titleSmall {
	font-size: $font-size-small;
	text-transform: uppercase;
}

//-----------------
// Body Type Styles
//-----------------
.bodyLarge {
	font-size: $font-size-base;
	font-weight: $font-weight-light;
}

.bodySmall {
	font-size: $font-size-base;
	font-weight: $font-weight-light;
}

.caption {
	font-size: $font-size-xsmall;
}
