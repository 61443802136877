//-------------
// Parrot Colours
//-------------
$darker-purple: #05081a;
$dark-purple: #111747;
$light-purple: #545872;
$yellow: #FFC641;
$orange: #F85D34;
$green: #06818E;


//-------------
// Base Colours
//-------------
$darker-grey: rgba(0,0,0,0.6);
$dark-grey: rgba(0,0,0,0.4);
$grey: #25273E;
$light-grey: #343657;
$lighter-grey: #8A8CAE;

//---------------
//Support Colours
//---------------
$support-success: #35E165;
$support-error: #FF3148;
$support-alert: #FEBB70;
$support-neutral: #0471FF;

//-------------
// Main Colours
//-------------
$primary: $orange;
$primary-soft: rgba($primary, 0.1);

$secondary: $yellow;
$secondary-soft: rgba($secondary, 0.1);

//--------
//Services
//--------
$service-twitch: #772CE8;
$service-mixer: #0075D5;
$service-facebook: #4267B1;
$service-youtube: #F01530;


//------------------
// Elevation Shadows
//------------------
$elevate-subtle: 0 1px 6px 0 rgba(0, 0, 0, 0.5);
$elevate-raised: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
$elevate-distant: 0 6px 16px 0 rgba(0, 0, 0, 0.5);

//-----------
//Decorations
//-----------
$border-radius: 0.25rem; // 4px
$border-radius-large: 0.5rem; // 8px

//-----------
//Transitions
//-----------
$transition-speed: .15s;
$transition-ease-type: ease-in-out;

$transition-all: all $transition-speed $transition-ease-type;

//-----
// Type
//-----
$font-size-base:          1rem;

$font-size-large:         calc(#{$font-size-base} * 0.9375); // 15px
$font-size-medium:        calc(#{$font-size-base} * 0.875); //14px
$font-size-small:         calc(#{$font-size-base} * 0.8125); // 13px
$font-size-xsmall:        calc(#{$font-size-base} * 0.75); // ~12px

$font-size-h1:            calc(#{$font-size-base} * 2); // 32px
$font-size-h2:            calc(#{$font-size-base} * 1.75); // 28px
$font-size-h3:            calc(#{$font-size-base} * 1.5); // 24px
$font-size-h4:            calc(#{$font-size-base} * 1.375); // 22px
$font-size-h5:            $font-size-base; // 16px
$font-size-h6:            $font-size-large; // 15px


//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    calc(#{$font-size-base} * #{$line-height-base}); // ~20px


//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    $font-weight-regular;
$headings-line-height:    1.1;
$headings-color:          inherit;
$headings-small-color:    $lighter-grey;

//----------------
// Service Colours
//----------------
$twitch-color:             #9146ff;
$youtube-color:            #FF0000;

//----------------
// Service Colours
//----------------
$stream-controls-height: 2.5rem;
$chat-banner-height: 1.75rem;