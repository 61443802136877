.stream-controls {
    height: $stream-controls-height;
    padding: 0.5rem 1rem;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        content: "";
        background-color:hsla(231,67%,6%,1);
        background-image:
        radial-gradient(at 41% 0%, hsla(360,100%,13%,1) 0px, transparent 50%),
        radial-gradient(at 75% 0%, hsla(264,100%,13%,1) 0px, transparent 50%);
        height: 100dvh;
    }

    button {
        margin-right: 1rem;
        border: none;
        background: none;
        color: white;

        &:hover {
            text-decoration: underline;
        }
    }

    .the-controls {
        align-self: flex-start;

        .the-chat-toggle {
            @media screen and (max-height: 479px) {
                display: none;
            }
        }
    }

    .the-name {
        align-self: flex-end;
        text-align: right;

        a {
            color: #fff;
            text-decoration: none;
            display: inline-block;

            &:hover {
                opacity: 0.6;
            }
        }
        
        h1 {
            margin: 0;
            padding: 0 0.5rem 0 0;
            font-family: $font-family-krona;
            font-size: 0.9375rem;
            line-height: 1.55;
        }
    }

    @include media-breakpoint-down(md) {
        position: relative;
        z-index: 110;
        .focus-mode-toggle {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .the-menu {
            margin-right: 0rem;
        }
        &.one-channel {
            .the-chat-toggle {
                display: none;
            }
        }
    }
}

.streams-container {
    .channel-form {
        margin: 0;
        width: 24rem;
        padding: 2rem;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        border-radius: 0.25rem;
        border: 1px solid $light-purple;
        background-color: $darker-purple;
        z-index: 1000;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            @include make-col-offset(0);
            left: 0.5rem;
            width: auto;
            max-width: 100%;
        }
    }
}

.stream-background {
    position: absolute;
    z-index: 1;
    content: "";
    display: block;
    top: $stream-controls-height;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
}