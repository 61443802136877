.streams-container {
    .video-tabs {
        display: none;
    }

    &.focus-mode {
        .the-videos {
            @include make-col(7);
            position: relative;
            align-items: center;
        }
        .the-chats {
            @include make-col(5);
        }

        .player-container {
            &.focus {
                z-index: 1;
                opacity: 1;
                
                .channel-offline {
                    width: 100%;
                    height: 100%;
                    background-color:hsla(0,0%,0%,1);
                    background-image:
                    radial-gradient(at 100% 1%, hsla(0,100%,50%,0.37) 0px, transparent 50%),
                    radial-gradient(at 0% 100%, hsla(0,100%,50%,0.13) 0px, transparent 50%);
                }
            }
        }

        .video-tabs {
            position: absolute;
            bottom: 0rem;
            padding-bottom: 0.5rem;
            width: 100%;
            z-index: 99999;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-x: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }

            .tab {
                margin: 0 1rem;
                cursor: pointer;
                display: block;
                height: 1.5rem;
                line-height: 1;
                transition: $transition-all;
                opacity: 0.5;
                border-bottom-width: 1px;
                border-bottom-style: solid;

                span {
                    padding: 0 0.5rem;
                }

                &:hover {
                    opacity: 1;
                }

                &.twitch {
                    border-bottom-color: rgba($twitch-color, 0.5);
                    &:hover {
                        border-bottom-color: rgba($twitch-color, 0.5);
                    }
                }

                &.youtube {
                    border-bottom-color: rgba($youtube-color, 0.5);
                    &:hover {
                        border-bottom-color: rgba($youtube-color, 0.5);
                    }
                }
            }

            .active {
                display: inline-block;
                opacity: 1;

                &:hover {
                    opacity: 1;
                }

                &.twitch {
                    border-bottom-color: $twitch-color;
                    &:hover {
                        border-bottom-color: rgba($twitch-color, 1);
                    }
                }

                &.youtube {
                    border-bottom-color: $youtube-color;
                    &:hover {
                        border-bottom-color: rgba($youtube-color, 1);
                    }
                }

            }
        }
    }

    // Layouts
    // 2 channels
    &.focus-mode.two-channels {
        flex-direction: row;

        .the-videos {
            .player-container {
                margin: 0 auto;
                position: absolute;
                width: 100%;
                aspect-ratio: 16 / 9;
                justify-self: center;
                display: flex;
                max-height: inherit;
                z-index: -999;
                opacity: 0;

                &.focus {
                    z-index: 1;
                    opacity: 1;
                }
            }
        }

        // Vertical Layout option moved to non-focus mode
        // @include media-breakpoint-down(lg) {
        //     .video-tabs {
        //         display: none;
        //     }
        //     // border: 1px solid red;
        //     flex-direction: column;
        //     .the-videos,
        //     .the-chats {
        //         @include make-col(12);
        //     }

        //     .the-videos {
        //         max-height: 50dvh;
        //         .player-container {
        //             position: relative;
        //             z-index: 1;
        //             opacity: 1;
        //             max-height: 25dvh;
        //         }
        //     }

        //     .the-chats {
        //         height: calc(50dvh - #{$stream-controls-height});
        //     }
        // }
    }

    // 3 channels
    &.focus-mode.three-channels {
        flex-direction: column;
        flex-wrap: wrap;

        .the-videos {
            @include make-col(12);
            aspect-ratio: 16 / 9;
            max-height: calc(50dvh - #{$stream-controls-height});
            width: 100%;
            justify-content: center;

            .player-container {
                margin: 0 auto 2rem;
                position: absolute;
                height: calc(100% - 2rem);
                width: auto;
                aspect-ratio: 16 / 9;
                display: flex;
                max-height: inherit;
                z-index: -999;
                opacity: 0;

                &.focus {
                    z-index: 1;
                    opacity: 1;
                }
            }

            .video-tabs {
                align-items: flex-end;
                justify-content: stretch;
                margin: 0;
                padding: 0;
                bottom: 0;

                .tab {
                    margin: 0;
                    width: 33.3%;
                }
            }
        }

        .the-chats {
            height: 50dvh;
            @include make-col(12);
        }

        &.hide-chat {
            .video-tabs {
                justify-content: center;
                padding-bottom: 0.5rem;

                .tab {
                    margin: 0 1rem;
                    width: auto;
                }
            }
            .the-videos {
                height: calc(100dvh - 2.5rem);
                max-height: 100%;
                .player-container {
                    height: calc(100% - 2.5rem);
                    max-width: 100%;
                }
            }
        }
    }

    &.focus-mode.four-channels,
    &.focus-mode.five-channels,
    &.focus-mode.six-channels {
        flex-direction: row;

        .the-videos {
            height: 100%;
            .player-container {
                margin: 0 auto;
                position: absolute;
                width: 100%;
                aspect-ratio: 16 / 9;
                align-self: center;
                justify-self: center;
                display: flex;
                max-height: inherit;
                z-index: -999;
                opacity: 0;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    width: 100%;
                    height: auto;
                    align-self: center;
                }

                &.focus {
                    z-index: 1;
                    opacity: 1;
                }
            }
        }

        &.hide-chat {
            .the-videos {
                max-height: calc(100dvh - 2.5rem);
                .player-container {
                    align-self: flex-start;
                    height: calc(100% - 2.5rem);
                    max-width: 100%;
                }
            }
        }
    }

    &.focus-mode.hide-chat {
        .channel-container {
            align-items: flex-start;
        }
        .player-container {
            height: calc(100dvh - #{$stream-controls-height} - 2.5rem);
            width: auto;

            .channel-offline {
                max-height: calc(100dvh - #{$stream-controls-height} - 2.5rem);
                max-width: 100dvw;
                aspect-ratio: 16 / 9;
            }
        }
    }
}

/* CSS specific to Safari on iPad Pro */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: portrait) {
    .streams-container {
        
        &.focus-mode {
            .video-tabs {
                bottom: 1.25rem;
            }
        }
    }
}


@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape) {
    .streams-container {
        
        &.focus-mode {
            .video-tabs {
                bottom: 1.25rem;
            }
        }
    }
}