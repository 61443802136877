body {
    margin: 0;
    padding: 0;
    color: #fff;
    background-color: $darker-purple;
    font-family: $font-family-inconsolata;
    font-weight: $font-weight-regular;
    font-size: 16px;
}

#root {
    @extend .container-fluid;
    margin: 0;
    padding: 0;
}
