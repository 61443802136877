.channel-form {
    @include make-col-ready();
    @include make-col(10);
    @include make-col-offset(1);

    form {
        // max-width: 20rem;
    }

    button {
        @extend .button;
        @extend .secondary;
    }

    .channel-list {
        .channel-list-item, .form-error {
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            max-width: 20rem;
            position: relative;
        }

        .input {
            height: 2.5rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-width: 1px;
            border-color: $light-purple;
            border-left: 0;
            background-color: black;
            font-size: 0.9375rem;
        }
        
        .channel-toggle {
            min-width: auto;
            color: #fff;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(#fff, 0.4);
            padding: 0.2rem 0.75rem;
            height: 2.5rem;
            border-radius: 0;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            text-align: center;
            transition: $transition-all;
            text-transform: lowercase;

            &:hover {
                cursor: pointer;
            }

            &.twitch {
                border-color: $twitch-color;
                background-color: black;
                box-shadow: inset 0 0 100px rgba($twitch-color, 0.15);
                &:hover {
                    box-shadow: inset 0 0 100px rgba($twitch-color, 0.4);
                }
            }

            &.youtube {
                border-color: $youtube-color;
                background-color: black;
                box-shadow: inset 0 0 100px rgba($youtube-color, 0.15);
                &:hover {
                    box-shadow: inset 0 0 100px rgba($youtube-color, 0.4);
                }
            }
        }

        .remove-channel {
            margin-left: 0.25rem;
            padding: 0.25rem;
            width: 2.5rem;
            height: 2.5rem;
            min-width: 2.5rem;
            background-color: transparent;
            // background-color: black;
            border: 1px solid transparent;
            position: absolute;
            right: 0;
            bottom: 0;

            &:hover {
                border: 1px solid white;
            }
        }

        .form-error {
            padding-top: 0.5rem;
            p {
                color: $support-alert;
                font-size: 0.8125rem;
            }
        }
    }

    .add-channels {
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;

        .button {
            padding-left: 1rem;
            padding-right: 1rem;
            border-width: 1px;
            min-width: 2.5rem;
            text-transform: none;
            font-size: 0.9375rem;
            border-color: rgba(#fff, 0.3);
            font-weight: $font-weight-bold;

            &.twitch {
                margin-right: 1rem;
                border-color: $twitch-color;
                background-color: black;
                box-shadow: inset 0 0 100px rgba($twitch-color, 0.15);
                &:hover {
                    box-shadow: inset 0 0 100px rgba($twitch-color, 0.4);
                }
            }
            &.youtube {
                border-color: $youtube-color;
                background-color: black;
                box-shadow: inset 0 0 100px rgba($youtube-color, 0.15);
                &:hover {
                    box-shadow: inset 0 0 100px rgba($youtube-color, 0.4);
                }
            }
        }
    }

    .layout-options {
        margin-bottom: 2.5rem;
        display: flex;
        flex-direction: row;
        max-width: 20rem;

        .label {
            flex: 1 auto;
            padding-bottom: 0;
            font-weight: $font-weight-regular;
        }
        .toggle-switch {
            align-self: flex-end;
            @extend .toggle;
        }

        .hide-chats {
            display: none;
        }
    }

    .start-watching {
        button {
            border-color: white;
            background-color: black;
            font-weight: $font-weight-bold;

            &:hover {
                background-color: white;
                color: black;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        @include make-col(12);
        @include make-col-offset(0);

        .ios-safari, .channel-form, .channel-list {
            .input {
                font-size: 1rem;
            }
        }

        .channel-list {
            .channel-list-item {
                max-width: 100%;
            }
        }

        .layout-options {
            max-width: 100%;
        }
    }
}