@import 'channel-form';

.home-container {
    @include make-row();
    margin-top: 6.5rem;
    padding-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
    align-items: center;

    @include media-breakpoint-down(md) {
        margin-top: 2rem;
    }

    &:before, &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        content: "";
        background-color: hsla(231,67%,6%,1);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: fixed;
        transition: $transition-all;
        animation-duration: 8s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-delay: 0;
    }

    &:before {
        background-image:
            radial-gradient(at 97% 75%, hsla(360,100%,21%,1) 0px, transparent 50%),
            radial-gradient(at 97% 24%, hsla(264,100%,21%,1) 0px, transparent 50%);
        animation-name: animatedgradienbefore;
    }

    &:after {
        background-image:
            radial-gradient(at 97% 24%, hsla(360,100%,21%,1) 0px, transparent 50%),
            radial-gradient(at 97% 75%, hsla(264,100%,21%,1) 0px, transparent 50%);
        animation-name: animatedgradientafter;
    }

    @keyframes animatedgradienbefore {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes animatedgradientafter {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

    .intro, .login-button, .messages, .version-track {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            @include make-col-offset(0);
        }
    }

    .intro {
        h1 {
            max-width: 58rem;
            margin-bottom: 2.5rem;
            font-family: $font-family-krona;
            font-weight: $font-weight-regular;
            font-size: clamp(1.5rem, 5vw, 2.5rem);
            line-height: 1.5;
        }
    }

    .messages {
        max-width: 28rem;
        margin-top: 4rem;
        font-size: 0.75rem;
        opacity: 0.5;
        span {
            font-weight: bold;
        }
    }

    .version-track {
        font-size: 0.75rem;
        opacity: 0.25;
    }

    .login-button {
        margin-top: 2rem;
        // display: none;
    }

    .loading {
        margin-top: 2.5rem;
    }
}